::-webkit-scrollbar {
  display: none;
}

body .attachment-ul .attachment-li {
  max-width: 60px;
}

body .chat .chat-customer-list .chat-customer-list__wrapper .chat-customer-list__ul li {
  padding:10px 4px;
}


.chat {
  .chat-customer-list {
    overflow: hidden;
    height: calc(100vh - 240px) !important;
    min-height: 250px;

    .chat-customer-list__wrapper {
      height: calc(100vh - 255px);
      width: 100%;

      .chat-customer-list__search {
        width: 100%;
        margin-bottom: 10px;
      }

      .chat-customer-list__ul {
        padding: 10px 0;
        margin: 0;
        list-style-type: none;

        li {
          cursor: pointer;
          padding: 4px;

          &:hover {
            background: rgba(0, 0, 0, 0.04);
            box-shadow: var(--box-shadow-low);
          }
        }


        .chat-list-item {
          .chat-list-item__wrapper {

            .chat-list-wrapper_sub {
              position: relative;

              .chat-list-wrapper_sub_line_1 {
                display: flex;
                align-items: flex-start;
                // margin-bottom: 10px;

                .chat-list-wrapper_image {
                  width: 50px;
                  height: 50px;
                  min-width: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .emojiFlag {
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }

                .chat-list-wrapper_name_wrapper {
                  padding-left: 10px;
                  flex: 1;
                  display: flex;
                  justify-content: space-between;

                  .chat-list-wrapper_name {
                    max-width: 80%;
                    max-lines: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    span {
                      max-width: 50px;
                      font-size: 1rem;

                      margin-bottom: 0.5rem;
                      font-weight: 800;
                      line-height: 1.2;
                    }
                  }
                }

              }

              .chat-list-wrapper_sub_line_2 {
                display: flex;
                justify-content: space-between;
                padding-left: 5px;
                width: 300px;
                position: absolute;
                top: 20px;
                left: 56px;

                .chat-list-item-message__wrapper {
                  width: 60%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  span {
                    max-lines: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: var(--color-2);

                    font-weight: lighter;
                    font-size: 0.9rem;
                  }
                }

                .chat-list-item-message__time {
                  text-align: right;

                  .chat-list-item-time {
                    color: var(--color-2);
                    font-weight: lighter;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }

      .chat-customer-list__ul:last-child {
        margin-bottom: 0px;
      }

    }
  }

  .chat-detail {
    height: 85vh;
    width: inherit;
    min-width: 250px;
    flex: 1;
  }

  .productsScroll>.infinite-scroll-component__outerdiv {
    overflow-y: visible !important;
  }

  .details-card {
    padding: 0 0 !important;
    height: calc(100vh - 215px);
    min-width: 250px;
    flex: 1;
    overflow-y: hidden !important;
    background-color: #FAF9FE;
    box-shadow: none !important;

    .infinite-scroll-component__outerdiv {
      height: calc(100% - 130px) !important;
      overflow-y: auto;
    }

    .back-button {
      svg {
        margin-right: 15px;
        border-radius: 35%;
        background-color: #f5f5f5;
        padding: 10px;
        font-size: 40px;
        cursor: pointer;
      }
    }
  }

  .chat-customer-list__wrapper .ScrollbarsCustom {
    height: calc(100vh - 300px) !important;
  }

  .details-card .ScrollbarsCustom-Track {
    right: -15px !important;
    height: calc(100% - 50px) !important;
  }

  .recommendations-card .ScrollbarsCustom-Track {
    height: calc(100% - 70px) !important;
  }

  .details-card .ScrollbarsCustom-TrackX {
    display: none;
  }

  .product-recommends {
    // border: 3px solid #dddddd;
    border-bottom-color: #000000;
    height: 100%;

    img {
      width: 100% !important;
      height: auto !important;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

  }

  .product-view {
    background-color: #000000;
    text-align: center;
    font-size: 1.2em;
    color: #ffffff;
  }

  .chat-list-tile {
    cursor: pointer;
    padding: 0.5rem;
    max-height: 100%;
    overflow: scroll;

    &:hover {
      box-shadow: var(--box-shadow-low);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .chat-list-heading {
    cursor: pointer;
    padding: 0.5rem;
    color: #048D16;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .chat-body {
    min-height: 250px !important;
    overflow: hidden;
    height: calc(100vh - 209px) !important;


    .chat-body-header {
      height: fit-content;
      border-bottom: 1px solid #eee;

      ul {
        background: #fefefe;
        width: fit-content;
        border-bottom-right-radius: 20px;
        box-shadow: 1px 3px 21px #eeeeeeba;

        li {
          vertical-align: top;
          border-bottom-right-radius: 20px;
        }
      }

      .open {
        border-top: 5px solid var(--color-primary-active);
        background: #fefefe;
      }

      img {
        &:last-child {
          width: 24px;
          margin-top: 8px;
        }
      }

      div {
        margin-left: 10px;
        margin-right: 10px;
      }

      h2 {
        font-size: 16px;
        margin-bottom: 5px;
      }

      a {
        color: #6500ff;
        font-weight: 200;
      }

      h3 {
        font-size: 14px;
        font-weight: normal;
        color: #7e818a;
      }
    }

    .chat-body-root {
      height: calc(100vh - 240px);
      overflow: hidden;
      min-width: 250px;
    }

    .chat-area-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .chatCannedPopup .chat-area-scrollbar {
        max-height: calc(100vh - 319px);
        height: 300px !important;
        min-height: 100px;
      }

      .chat-area-scroll-wrapper {
        flex-grow: 1;

        .ScrollbarsCustom {
          height: calc(-290px + 97vh) !important;
        }

        .chat-area-scrollbar {
          .ScrollbarsCustom-Scroller {
            padding-bottom: 70px;
            background-color: #f1eff9 !important;
            border-radius: 12px !important;
          }

          .chat-area {
            padding: 8px;
            margin: 0;
            list-style-type: none;
            border-top: 2px solid #fff;

            li {
              padding: 10px 30px;
            }

            .sender-info {
              margin-bottom: 5px;
            }

            .me {
              text-align: right;

              .message {
                overflow-wrap: break-word;
                background-color: white;
                border-radius: 15px 15px 0 15px;
                border-color: #e9e9e9;

                &::after {
                  position: absolute;
                  border-style: solid;
                  content: "";
                  border-width: 0 4px 6px 0;
                  border-color: transparent transparent #fafafd;
                  right: -4px;
                  bottom: 0;
                }

                &::before {
                  border-width: 0 5px 7px 0;
                  border-color: transparent transparent #e9e9e9;
                  right: -6px;
                  bottom: -1px;
                }
              }
            }

            .message {
              font-size: 1rem;
              position: relative;
              padding: 12px 25px;
              color: #000;
              line-height: 25px;
              max-width: 90%;
              display: inline-block;
              text-align: left;

              &::before {
                position: absolute;
                border-style: solid;
                content: "";
              }
            }

            .user-detail {
              display: flex;
              flex-direction: column;
              align-items: center;

              .avatar {
                margin-bottom: 2px;

                .profile-photo {
                  width: 50px;
                  height: 50px;
                  min-width: 50px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    font-size: 2em;
                    line-height: 2em;
                    object-fit: cover;
                  }
                }
              }

              .user-name {
                text-align: center;
              }
            }

            .me {
              .user-detail {
                float: right;
                margin-inline: 10px;
              }

              .sender-info {
                margin-bottom: 5px;
                margin-right: 60px;
              }
            }

            .you {
              .user-detail {
                float: left;
                margin-right: 10px;
              }

              .message {

                overflow-wrap: break-word;
                background-color: rgba(0, 0, 0, .060);
                border-radius: 15px 15px 15px 0;
                border-color: var(--color-14);

                &::before {
                  border-width: 0 5px 7px 0;
                  border-color: transparent transparent #e9e9e9;
                  right: -6px;
                  bottom: -1px;
                }
              }

              .user-name {
                text-align: center;
              }

              .sender-info {
                margin-bottom: 5px;
                margin-left: 60px;
              }
            }

            small {
              font-weight: lighter;
              font-size: .7rem;
              color: #6e6e6e;
            }

            h3 {
              font-size: 0.8rem;
              display: inline-block;
              color: black;
              margin: 4px;
            }

            h2 {
              display: inline-block;
              color: black;
              margin: 4px;
              font-size: 1rem;
              font-weight: bold;
            }

            img {
              width: 20px;
              height: 20px;
              vertical-align: bottom;
            }

            .you {
              img {
                margin-right: 2%;
              }
            }

            .me {
              img {
                margin-left: 2%;
              }
            }
          }
        }
      }

      .chat-area__input_area {
        position: absolute;
        bottom: 0;
        width: 96%;
        background: white;
        box-shadow: 0 8px 12px #ede9ff;
        z-index: 10;

        #messageInput {
          background: none !important;
          color: #000000;
        }

        .chat-area__input_file_upload {

          position: relative;

          .chat-area__input_file_upload_sub {
            padding: 4px;
            background: #e6e6ee;
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            top: -50px;

            .chat-area__input_file_upload_sub_text {
              font-size: 12px;
            }
          }
        }

        .chat-area__input_area__form {
          display: flex;
          align-items: center;

          .btn-black {
            margin: 10px 0;
            border-radius: 4px;

            img {
              width: 18px;
              margin-right: 10px;
              top: -2px;
              position: relative;
            }
          }

          .MuiIconButton-root {
            padding: 5px;
          }
        }
      }
    }

    footer {
      height: 155px;
      padding: 20px 30px 10px 20px;

      textarea {
        resize: none;
        border: none;
        display: block;
        width: 100%;
        height: 80px;
        border-radius: 3px;
        padding: 20px;
        font-size: 13px;
        margin-bottom: 13px;

        &::placeholder {
          color: #ddd;
        }
      }

      img {
        height: 30px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        color: #6fbced;
        vertical-align: top;
        margin-left: 333px;
        margin-top: 5px;
        display: inline-block;
      }
    }
  }

  .chat-detail {
    overflow: hidden;
    height: calc(100vh - 225px) !important;
    min-height: 250px;
  }

  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
  }

  .green {
    background-color: #58b666;
  }

  .orange {
    background-color: #ff725d;
  }

  .blue {
    background-color: var(--color-primary);
    margin-right: 0;
    margin-left: 7px;
  }

  .chat-list-card {
    width: 100%;

    img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    li {
      padding: 5px 10px 5px 0;
      box-shadow: 0 4px 7px #cacaca47;

      cursor: pointer;
      list-style-type: none;

      &:last-child {
        margin-bottom: unset;
      }

      &:hover {
        padding: 5px 10px 5px 0;
        box-shadow: 0 4px 7px #cacaca47;
      }
    }


    .chat-list-card-user-names {
      color: #6D6D6D;
      font-weight: 200;
      font-size: 1.1rem;
    }

    .chat-list-item-message {
      font-size: 0.7rem;
      vertical-align: text-top;
    }
  }

  .customer-detail-chat {
    margin-bottom: 0;
    min-height: 250px;

    .customer-detail-chat_wrap {
      background: #ffffff;
      padding: 15px 15px 5px 15px;
      border-radius: 30px 30px 30px 0;
    }

    .customer-detail-chat__text {
      color: #000;
      margin-bottom: 3px;
      font-weight: lighter;
      font-size: 0.9rem;
      padding: 0 10px;

      img {
        width: 25px;
      }

      h3.profile-name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }

      svg {
        font-size: 15px;
        color: #6500ff;
      }

      svg.edit-pencil {
        font-size: 28px;
      }

      .profile-detail {
        .icon {
          display: inline-block;
          width: 25px;
          height: 25px;
          text-align: center;
          vertical-align: middle;
          background-color: #e9d0ff;
          border-radius: 50%;
          padding: 2px;
          margin: 0;

          img {
            width: 18px;
            border-radius: 0;
          }
        }

        .text {
          word-break: break-word;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          overflow-wrap: anywhere;
          width: auto;
          font-size: .95rem;
          // font-weight: 500;
          color: #434343;
        }

        img {
          width: 100%;
          border-radius: 25px;
        }

        .button-cart {
          text-align: center;
          margin-top: 10px;

          svg {
            color: #FFFFFF;
          }

          .text{
            color: #ffffff;
          }

          .cart-count{
            background-color: white;
            border-radius: 20px;
            color: #000;
            margin-bottom: 15px;
            padding: 0px 5px;
          }

          p {
            display: inline-block;
            background-color: #692AFF;
            color: #fff;
            padding: 5px 10px;
            margin: 0 auto;
            width: 249px;
            height: 49px;
            cursor: pointer;
            border-radius: 4px;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 14px;

            span.right {
               margin-left: 10px;
            }
          }

          img {
            width: 26px;
            border-radius: 0;
          }
        }

        .button-view-visitor {
          p {
            display: inline-block;
            color: #692AFF;
            padding: 5px 10px;
            margin: 0;
            vertical-align: middle;
            text-align: left;
            width: 245px;
            cursor: pointer;

            span.right {
              margin-left: 15px;
              color: #6500ff;
            }

            svg {
              color: #1a1a1a;
              // margin-right: 15px;
              border-radius: 51%;
              background-color: #F5F5F5;
              padding: 10px;
              font-size: 40px;
            }
          }
        }
      }

    }

    .customer-detail-chat__text.profile-image {
      .profile-image-inner:not(:first-child) {
        display: none;
      }

      .profile-image-inner:first-child {
        display: flex;
        justify-content: center;
        margin-top: 20%;
      }

      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .customer-detail-chat__text.visitor-parent {
      margin-bottom: 0;
    }

  }

  .customer-detail-orders {
    margin-bottom: 0;
    padding-bottom: 0 !important;

    .customer-detail-chat_wrap {
      background: #ffffff;
      padding: 15px;
      min-height: 250px;
      border-radius: 5px;
    }

    .customer-detail-chat__text {
      color: #000;
      margin-bottom: 10px;
      // font-weight: 600;
      font-size: 0.9rem;
      padding: 0 10px;

      .image-wrapper {
        background-color: #e9d0ff;
        padding: 2px;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;

        img {
          width: 25px;
          margin: auto;
        }
      }

      .profile-name {
        margin-bottom: 0;

        span {
          font-size: 18px;
          font-weight: 500;
        }
      }

      svg {
        font-size: 18px;
      }

      svg.edit-pencil {
        font-size: 28px;
      }

      .reviews {
        svg {
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .profile-detail {
        .icon {
          display: inline-block;
          width: 25px;
          height: 25px;
          text-align: center;
          vertical-align: middle;
          background-color: #daceeb;
          border-radius: 50%;
          padding: 2px 4px;

          img {
            width: 20px;
            border-radius: 0;
          }
        }

        .text {
          word-break: break-word;
          display: inline-block;
          vertical-align: middle;
          overflow-wrap: anywhere;
          width: auto;
        }

        img {
          width: 100%;
          border-radius: 25px;
        }

        .button-cart {
          text-align: center;

          svg {
            color: #cc85ff;
          }

          p {
            display: flex;
            align-items: center;
            background-color: #692aff;
            color: #fff;
            padding: 5px 15px;
            margin: 0 auto;
            vertical-align: middle;
            text-align: center;
            width: 150px;
            height: 49px;
            cursor: pointer;
            border-radius: 4px;
            font-size: 14px;

            .text {
              display: flex;
              text-align: center;
              margin: auto;
            }

            span.right {
              margin-left: 25px;
            }
          }

          img {
            width: 26px;
            border-radius: 0;
          }
        }

        .button-cart.recommended {
          p {
            background-color: #000302;
            border-radius: 4px;
            width: 150px;

            .img-icon {
              img {
                margin-left: 15px;
              }
            }
          }
        }
      }
    }

    .customer-detail-chat__text.profile-image {
      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .customer-detail-chat__text.visitor-parent {
      margin-bottom: 0;
    }

  }


  .customer-detail-attachments {
    .customer-detail-chat_wrap {
      background: #ffffff;
      padding: 20px 15px 5px 15px;
      border-radius: 0 40px 40px 40px;
      min-height: 250px;

      .view-all {
        display: inline-block;
        color: #692aff;
        padding: 5px 10px;
        margin: 0;
        cursor: pointer;
      }
    }
  }


  .chat-panel-items {
    color: #000;

    .chat-panel-item {
      position: relative;
      padding: 15px;
      border-radius: 5px;
      background-color: #fafafd;
      margin-bottom: 5px;
    }

    .chat-panel-item:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }

    .img-icon {
      position: absolute;
      font-size: 20px;
      top: 22px;
      width: 25px;
    }

    button img {
      width: 18px;
      margin-right: 10px;
    }

    p {
      margin-left: 40px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 18px;
      position: absolute;
      right: 15px;
      top: 28px;
      font-weight: bold;

      svg {
        top: -2px;
        position: relative;
        padding-left: 5px;
      }
    }
  }

  .cart-products {
    svg {
      top: auto;
    }
  }

  .products-actions {
    padding-top: 5px !important;
    background: #ffffff;
    bottom: 58px;
    position: relative;
    border-top: 1px solid #eee;
    margin: -20px 15px;
  }

  .products {
    padding-bottom: 10px;

    .ScrollbarsCustom-Content {
      margin-bottom: 50px;
    }

    img {
      border: 3px solid #e8e8f0;
      border-radius: 4px;
    }

    .active img {
      border-color: #6500ff;
    }

    .active::before {
      content: "\2713";
      position: absolute;
      display: inline-block;
      vertical-align: top;
      color: #fff;
      top: -2px;
      right: 12px;
      font-size: 10px;
      border-radius: 4px;
      font-weight: bold;
      padding: 2px 5px;
      background-color: #6500ff;
    }

    p.title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .has-search .form-control {
    padding-left: 2.375rem;
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .user-activity {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    p {
      color: #6500ff;
      cursor: pointer;
    }
  }

  .chatCannedPopup {
    background: #fff;
    position: absolute;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px -3px #00000080;
    bottom: 70px;
    overflow: auto;
    z-index: 99;

    .cannedMessage {
      padding: 5px;
      border-radius: 4px;
    }

    .cannedMessage:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  .itemsWrap {
    .itemTop:hover {
      cursor: pointer;
    }

    .itemTop span {
      position: relative;
      top: 2px;
    }

    .cartItem:hover,
    .orderItem:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }

    .orderItem {
      color: var(--color-2);
      padding: 10px;
      overflow-wrap: anywhere;

      .orderStatus {
        font-size: 1.5em;

        .badge {
          font-size: 14px;
          letter-spacing: normal;
          text-transform: capitalize;
        }
      }

      .datePrice {
        font-weight: bold;
      }

      .orderStatus,
      .datePrice {
        div {
          display: inline-block
        }

        div:nth-child(2) {
          float: right;
        }
      }
    }

    .cartItem {
      color: var(--color-2);
      padding: 10px;
      display: flex;
      justify-content: space-between;

      div {
        box-sizing: border-box;
        display: inline-block;
      }

      .info {
        flex: 1 1 auto;
        width: -webkit-calc(100% - 140px);
        width: -moz-calc(100% - 140px);
        width: calc(100% - 140px);
        top: 5px;
        position: relative;
      }

      .image {
        flex: 0 0 70px;
        width: 70px;
        min-width: 70px;
      }

      img {
        max-width: 70px;
        max-height: 70px;
      }

      .title,
      .description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .price {
        flex: 0 0 70px;
        min-width: 70px;
        float: right;
        text-align: right;
        top: 5px;
        position: relative;
      }

      p {
        margin-bottom: 5px;
      }

      .info p {
        margin-left: 5px;
      }

      .price p:nth-child(2),
      .info p:nth-child(2) {
        margin-bottom: 0;
      }

      .title {
        font-size: 1.2em;
      }
    }
  }

  .attachment {
    li {
      list-style-type: none;
      list-style-type: none;
      height: 50px;
      width: 55px;

      >* {
        height: 100%;
        width: 100%;
      }
    }

    img {
      height: 100%;
      width: 90%;
      margin-left: auto;
      border-radius: unset;
    }
  }

  .see-more {
    font-size: .75rem;
    font-weight: 500;
    color: #cacbca;
  }

}

.widget-setting {
  .app-card {
    padding: 0 !important;
  }

  .card-header {
    padding: 1.75rem 0;
    border-left: 5px solid #6500ff;
    border-bottom: 1px solid #6500ff;
  }

  .widget-setting-form {
    label {
      font-weight: 500;
      color: black;

      span {
        color: red;
      }
    }

    border-radius: 5px;

    input {
      &:-ms-input-placeholder {
        font-weight: 500;
      }
    }

  }
}

.search-wrapper {
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  .input-holder {
    height: 70px;
    width: 70px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;

    .search-input {
      width: 80%;
      height: 50px;
      padding: 0 20px 30px 20px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: black;
      display: flex;
      transform: translate(0, 60px);
      transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
      transition-delay: 0.3s;
    }

    .search-icon {
      width: 35px;
      height: 35px;
      border: none;
      border-radius: 6px;
      background: #6fbced;
      padding: 0;
      outline: none;
      position: relative;
      z-index: 2;
      float: right;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      span {
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        transform: rotate(45deg);
        transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);

        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 11px;
          left: -1px;
          top: 12px;
          border-radius: 2px;
          background-color: white;
        }

        &::after {
          position: absolute;
          content: '';
          width: 14px;
          height: 14px;
          left: -7px;
          top: 0;
          background-color: #6fbced;
          border-radius: 16px;
          border: 2px solid white;
        }
      }
    }
  }

  .close {
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;

    &::before {
      position: absolute;
      content: '';
      background: #6fbced;
      border-radius: 2px;
      width: 3px;
      height: 20px;
      left: 8px;
      top: 2px;
    }

    &::after {
      position: absolute;
      content: '';
      background: #6fbced;
      border-radius: 2px;
      width: 20px;
      height: 3px;
      left: 0;
      top: 10px;
    }
  }
}

.search-wrapper.active {
  .input-holder {
    width: 200px;
    height: 40px;
    border-radius: 50px;
    background: white;
    box-shadow: var(--box-shadow-low);
    transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);

    .search-input {
      opacity: 1;
      transform: translate(0, 10px);
    }

    .search-icon {
      width: 30px;
      height: 30px;
      margin: 10px;
      border-radius: 30px;
      top: -5px;

      span {
        transform: rotate(-45deg);
      }
    }
  }

  .close {
    right: -30px;
    top: 10px;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
  }
}

.attachment-ul {
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  .attachment-li {
    max-width: 100px;
    list-style-type: none;
    margin-bottom: 10px;
  }
}

.chat-customer-list__search {
  .MuiTab-root {
    min-width: unset;
  }

  input {
    margin-top: 10px;
  }
}

.chat-customer-list {
  .MuiCardContent-root {
    padding-top: 0;
  }
}

.btn-black,
.btn-black:hover:disabled,
.btn-black:disabled {
  background-color: #000;
  color: #fff;
  opacity: 1;

  span {
    font-size: 15px;
    margin-right: 15px;
  }
}

.btn-black:hover:enabled {
  background-color: #313131;
  color: #fff;
}

.btn-square {
  border-radius: unset;
  padding: 5px 20px;
}

.contact-area .img-icon {
  width: 18px !important;
  margin-right: 10px;
  top: 0;
}

.add-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chat-area__input_area__form button:nth-of-type(1) svg {
  width: 1.3em;
  height: 1.3em;
}

.details-card {
  overflow-y: auto !important;
}

.activeScroll .ScrollbarsCustom-TrackY {
  opacity: 1 !important;
}

.ScrollbarsCustom-Scroller {
  padding-right: 0 !important;
}

#scrollableProducts>.ScrollbarsCustom-Wrapper>.ScrollbarsCustom-Scroller {
  margin-right: 0 !important;
}

.customer-detail-chat_wrap {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.chatPaginator {
  display: flex;
  gap: 8px;
}
.p-dropdown-panel{
  background-color: white;
}