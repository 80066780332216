.notification-popup {
  width: 420px;
  min-height: 300px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .notification-popup__heading {
    span {
      font-size: 1.3rem;
      font-style: inherit;
      font-weight: 500;
      letter-spacing: -.01em;
      line-height: 1.16666667;
    }
  }

  .notification-popup__notifications {
    flex: 1;
    margin-top: 10px;

    .notification-popup__notifications__header {
      display: flex;
      justify-content: space-between;
      font-size: 0.9rem;
      color: var(--color-11);
    }

    .notification-popup_content {
      padding: 10px;
      display: flex;
      cursor: pointer;

      &.active {
      }

      .notification-popup_content_img {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      .notification-popup_content_message {
        padding-inline: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .notification-popup_content_message__span {
          font-size: 1rem;
          font-weight: 500;
          word-break: break-all;
        }
      }

      .notification-popup_content_actions {
        visibility: hidden;
        cursor: pointer;

        .notification-popup_content_actions__loading {
          display: flex;
          justify-items: center;
        }
      }

      &:focus-within, &:focus, &:hover {
        .notification-popup_content_actions {
          visibility: visible;
        }

        background: rgba(0, 0, 0, 0.04);
        box-shadow: var(--box-shadow-low);
      }
    }
  }

  .notification-popup__loader {
    flex: 0;
    display: flex;
    justify-content: center;

    span {
      font-size: 1.2rem;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.search-popover {
  width: 440px;
  height: calc(450px);
  overflow: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .search-popover__input {
    margin-left: -14px;
    margin-right: -14px;
    margin-top: -14px;
  }

  .search-popover__content__results {
    flex: 1;
    padding-top: 20px;

    .search-popover__content__results__no_results {
      text-align: center;
    }

    .search-popover__content__results__group {


      .search-popover__content__results__heading {
        span {
          font-size: 0.9rem;
          color: var(--color-11);
          text-transform: capitalize;
        }
      }

      .search-popover__content__results__content {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        .search-popover__content__results__content_icon {
          flex: 0;

        }

        .search-popover__content__results__content_result {
          padding-left: 10px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .search-popover__content__results__content_result__link {
            color: inherit;

            &:hover {
              text-decoration: underline;
            }
          }

          .search-popover__content__results__content_result__date {
            font-size: 0.8rem;
          }
        }

        &:hover {
          background: #f4f5f7;;
        }
      }
    }
  }

  .search-popover__content__more_loader {
    flex: 0;
    display: flex;
    justify-content: center;
  }
}

.app-popover_wrapper {
  padding-inline: 8px;


  font-size: 14px;

  .app-popover {
    display: flex;
    flex-direction: column;

    .app-popover_item {
      display: flex;
    }

    a {
      color: black;
      text-align: center;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background: #f4f5f7;
      }
    }
  }
}


.profile-photo-header {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
