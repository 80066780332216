@font-face {
  font-family: 'icomoon';
  src: url('https://dashboard.emailwish.com/assets/fonts/icomoon.eot?f3gr4z');
  src: url('https://dashboard.emailwish.com/assets/fonts/icomoon.eot?f3gr4z#iefix') format('embedded-opentype'),
  url('https://dashboard.emailwish.com/assets/fonts/icomoon.ttf?f3gr4z') format('truetype'),
  url('https://dashboard.emailwish.com/assets/fonts/icomoon.woff?f3gr4z') format('woff'),
  url('https://dashboard.emailwish.com/assets/fonts/icomoon.svg?f3gr4z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-files-empty:before {
  content: "\e925";
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
