.hover-list {
  li {
    display: flex;
    list-style: none;
    padding: 15px;
    border: dashed 2px #fff;
    border-radius: 3px;

    i {
      font-size: 28px;
      margin-right: 20px;
      color: #333;
      margin-top: 6px;
    }

    .lnr-file-add:before {
      content: "\e81e";
    }

    .list-body {
      padding-right: 100px;
      width: 100%;

      h4 {
        margin: 0 0 3px 0;
        font-size: 1.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }


  li:hover {
    border: dashed 2px #aaa;
  }

  li:hover .list-action {
    visibility: visible;
  }

  .template-start {
    cursor: pointer;
  }
}

.campaign-more-button {
  margin-top: 1rem;

}

.campaign-attachments {
  margin-top: 1rem;
  outline: none;

  :focus {
    outline: none;
  }

  .dropzone {
    min-height: 150px;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    border-radius: 10px;

    .dz-default.dz-message:before {
      content: "\e925";
      font-family: 'icomoon';
      font-size: 64px;
      position: absolute;
      top: 48px;
      width: 64px;
      height: 64px;
      display: inline-block;
      left: 50%;
      margin-left: -32px;
      line-height: 1;
      z-index: 2;
      color: #ddd;
      text-indent: 0;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .dz-default.dz-message {
      position: absolute;
      height: 230px;
      width: 100%;
      margin-top: -115px;
      top: 50%;
      left: 0;
      padding: 20px;
      text-align: center;
      border-radius: 5px;
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }

    .dz-default.dz-message span {
      font-size: 15px;
      cursor: pointer;
      color: #bbb;
      text-align: center;
      margin-top: 120px;
      display: block;
      font-weight: 500;
      text-shadow: 0 1px 1px #fff;
    }
  }
}

.campaign-attachments-attached-files {
  ul {
    li {
      list-style: none;
      border-top: 1px dotted #ccc;
      padding: 10px 0px;
      float: left;
      width: 100%;

      h6 {
        display: block;
        font-size: 17px;
        margin-bottom: 2px;
      }

      p {
        display: block;
        font-size: 13px;
      }

      svg {
        margin-top: 10px;
      }

      .name-wrapper {
        margin-left: 10px;
      }
    }
  }
}

.image_upload_div {


}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box
  }

  .thumbInner {
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .progress {
    position: absolute;
    width: 90px;
    z-index: 1;
    top: 90px;
  }
}

.campaign-overview {

  .campaigns-summary {
    font-size: 15px;
  }
}

.campaign-subscriber {


  .campaign-subscriber-email {
    font-size: 1rem;

    .badge {
      text-transform: uppercase;
    }
  }
}

.campaign-logs {
  .campaign-logs-type {
    text-transform: capitalize;
  }

  .badge {
    text-transform: uppercase;
  }
}
