.app-card {
    box-shadow: var(--box-shadow-low) !important;
    border: none;
}

.app-card-header {
    background-color: white;
    border-bottom: none;
}

.wide-card {
    width: 760px;
    background-color: #6500ff !important;
    display: flex;
    justify-content: end;
    align-items: center;
}
.wide-side-card {
    background-color: #6500ff !important;
    justify-content: end;
    align-items: center;
    border-radius: 4px;
}